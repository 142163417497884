import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "3em 3em 6em",
  },
  logo: {
    width: 142,
    height: 37,
  },
  socialLinks: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icons: {
    margin: "8px 0px",
    cursor: "pointer",
  },
  instaIcon: {
    margin: 8,
    cursor: "pointer",
  },
  contactRow: {
    display: "flex",
    alignItems: "center",
    margin: "10px 0",
  },
  text: {
    fontSize: ".875rem",
    fontWeight: 400,
    margin: 0,
    marginLeft: 8,
  },
  ctaContainer: {
    width: "100%",
    paddingTop: "3rem",
    boxSizing: "border-box",
  },
  btn: {
    width: "100%",
    padding: "12px 32px",
    fontSize: "1.5rem",
    fontWeight: 600,
    color: "#FFFFFF",
    backgroundColor: "#FF7800",
    border: "3px solid #FDEFEF",
    borderRadius: 8,
    filter: "drop-shadow(0px 1px 32px rgba(0, 0, 0, 0.25))",
  },
  sectionTitle: {
    margin: "12px 0 8px 0",
  },
  linkContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  link: {
    textDecoration: "none",
    flex: "50%",
    width: "100%",
    margin: "0.675em 0",
    fontFamily: "Poppins",
    fontSize: "0.875rem",
  },
});
