export const createPayment = async (
  transactionId,
  price,
  leadId,
  phoneNumber,
  redirectUrl
) => {
  try {
    const response = await fetch(`https://payphonepe-zn6mbtczxq-uc.a.run.app`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        transactionId,
        price,
        leadId,
        phoneNumber,
        redirectUrl,
      }),
    });
    const phonepeResponse = await response.json();
    return phonepeResponse;
  } catch (error) {
    return error;
  }
};

export const checkPaymentStatus = async (txnId) => {
  try {
    const response = await fetch(
      `https://checkstatusphonepe-zn6mbtczxq-uc.a.run.app?txnId=${txnId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const paymentStatus = await response.json();
    return paymentStatus;
  } catch (error) {
    return error;
  }
};
