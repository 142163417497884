import React, { useRef, useState, useEffect, useContext } from "react";
import { Layout } from "../../components/Layout";
import { useStyles } from "./details.styles";
import classNames from "classnames";
import { saveLeads, createTransaction, getLead } from "../../services";
import { sha512 } from "js-sha512";
import { FormValidator } from "../../utils/validators/formValidator";
import { optInGp } from "../../utils/optIn";
import { detailsValidationRules } from "../../utils/validators/detailsValidationRules";
import mixpanel from "mixpanel-browser";
import clevertap from "clevertap-web-sdk";
import { mixpanelEvents } from "../../services/mixpanel/events";
import { clevertapEvents } from "../../services/clevertap/clevertapEvents";
import { context } from "../../store/context";
import { useTranslation } from "react-i18next";
import { usePrice } from "../../hooks/usePrice";
import { useLanguage } from "../../hooks/useLanguage";
import { ACTIVE_PG } from "../../utils/constant";
import { updateTransaction } from "../../services";
import { createPayment } from "../../services/api/phonepeApis";
import {
  STORAGE_KEY,
  getStorageItem,
  removeStorageItem,
  setStorageItem,
} from "../../services/storage";
import { getCookies } from "../../utils";
const SCRIPT_URL = `https://script.google.com/macros/s/AKfycbxGkU5xk7a431rZxHvps4WIxRqY0yobkaJvgijmV_mUe-A2l-99SKMIUB3XqPC1XkGB/exec`;
export const Details = () => {
  const { formattedPrice, price } = usePrice();
  const { lang } = useLanguage();
  const formRef = useRef(null);
  const { utmSource, utmMedium, utmCampaign, utmContent, chat } =
    useContext(context);
  const source = getStorageItem(STORAGE_KEY.UTM_SOURCE);
  const { _fbc, _fbp } = getCookies();
  const prodUrl = `https://secure.payu.in/_payment`;
  const MERCHANT_KEY = `JZPQUm`;
  const SALT = `MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQC7uPqfikhx9pZobpY66Qbe7ZAyiWjbG1lgzyMckh/Niz8Spym6HzwVzbgxc9VPUXZH0JDR3JySzJh3h9UmwVvWVzcqXrt6TlJ8PrS61P4384F9hadJn/D0EKjQe6dM47meRn87PcGiEqtz351lYKR4PI1826a/EsAVhV24Frgi0Nb2QB1v9p5U513lD+yvEORJiIJZaMlpgXmUMe9FGTwiS2A+gfgPABAWqeKrE+C1F0lOAWBdGlecwFCCAIptS5DAVbHkps0KZjAvOiFsRIn5Q+Kg7bgfh8KYWQeQlzfz7+6t0f5NYgv+2dwj2nZbRFseSmmPfuKCzGEc2BmZoPUVAgMBAAECggEBAIckJ+jq2DSS3LzZv/UxBO1AAOsg2QfDrJhxBknvOE30zLdU+Ekxa0QXEV8okIvFjW0tzNQICf/0ol6KvvxjfwXTS7eI81adQ+yGB/3okAsflj0TFtd9+Sz7QUy1eD92QY6cGcRANUHERwHoHZt5821Lyl/0kRpFGVtk448/a9hDXDRNx681cTW8kw2CQCdhbQbgBBpibt1vK4S/99fmsKekaeZ1eCW7xhfyUb52p0JLKy3TcStzJJkxKskiXBfj7yGXASFg+kBm3We5aqaU5xfTgv9p9lIja0YBNwFqsnbwO0M+npIxczxmNHqMbcFT8R595rcxMg6XZSfnzQ8o8xECgYEA5okxz7TWkmmhNX9z4ve5+g3HoCHhVbEhTMLEOlyehhBg9yxzik3VS0BAr8eei++0NYUEh78cBbZy3Yz9NoK01KHpEb9vD7icKAJ8dSc5wrOklqUgAc/rbo3qfZLgKW+036zOz4yVuI0SSEa5oYwriaxC7/LYlZvh5BIgWcLchh8CgYEA0HUplfT15RhHWOTwwwRb3H46PKUZcuVsIP1h1NBaMgf9t/4oOITJYiwTJ44B0AB5YZE3MTgn8rmrV1ZDTgtGaclw7g+h5XbwySHJZudC1L8K6YST4gtR8M1EVzuKdJUD2mViPJ4dZ56GkarmZvR2U/BlVro7mKD6Oa0UvwqhFksCgYBgdftvq3002hnoJqI4z+rzZBypNkPw9SK32a79tI9oDSZlhiX6MsMSr5nhcyc16mB/HF9e5Wo2QbciWMICswIzDmRVVsKD030TiKbG5ljWzRALMKCJ4ZPjOGJrlK9S5zjJdqc6VNq4095Ye69klyAIhRmlCPny77y/D46VwGFBhQKBgESIYFWmpTjeVIaLLgRPaIwDC85oUQePGY92/jTjEuQj8GwPKNX8AeE+sEVu0HW70iwbe9O4rJmGJSifeZ0s48dfrLr3dLhl9B+L9hTYrwYWkNXY+m10X0oVkgRD1O8jFpM0hal8Khqo6YD2eK0cbdKG1CpBK9qwAdP4bTupmPAlAoGBAJDRSqVzaXfIlga3H1zXvZl/N71eH022i67FwOhD9YFQBWy/pwnAgar4/sn8Wq75I5L7KYBrIvgNEw+uvG6RzqJQUTwUqFGrDQlozUlb2Wnzv2JL4GyFGrQS6P6NIfPfI7L26XkckO+W7LEccTnNV3BgX1lEwDx9flImsCOCJr4g`;
  const amount = price;
  const productInfo = "rupiseva";
  const SUCCESS_URL =
    source && source !== "facebook"
      ? "https://seva.rupicard.com/assisted-success"
      : "https://seva.rupicard.com/payment-success";
  const FAILED_URL =
    source && source !== "facebook"
      ? "https://seva.rupicard.com/assisted-failed"
      : "https://seva.rupicard.com/payment-failed";
  const validator = new FormValidator(detailsValidationRules);
  const payuRef = useRef();
  const classes = useStyles();
  const { t } = useTranslation();
  const [details, setDetails] = useState({
    name: "",
    phoneNumber: "",
    dob: "",
    pincode: "",
    rejectedCredit: "",
    cibilScore: "",
    reasonToImprove: "",
    timeSlot: "",
    email: "",
    monthlyIncome: "",
  });
  const {
    name,
    phoneNumber,
    dob,
    pincode,
    rejectedCredit,
    cibilScore,
    reasonToImprove,
    timeSlot,
    email,
    monthlyIncome,
  } = details;

  const [txnId, setTxnId] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [validation, setValidation] = useState(validator.valid());

  const onRadioChange = (field = "", value = "") => {
    setDetails((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const setTimeSlot = (value) => {
    setDetails((prevState) => ({
      ...prevState,
      timeSlot: value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const validation = validator.validate(details);
    const formattedPhoneNumber = phoneNumber.slice(-10);
    setValidation(validation);
    setFormSubmitted(true);
    if (validation.isValid) {
      setSubmitting(true);
      clevertap.init(process.env.REACT_APP_CLEVERTAP_PROJECT_ID, "eu1");
      clevertap.privacy.push({ optOut: false });
      clevertap.privacy.push({ useIP: true });
      clevertap.spa = true;

      try {
        const { id: leadId } = await saveLeads({
          ...details,
          phoneNumber: formattedPhoneNumber,
          utmSource,
          utmMedium,
          utmCampaign,
          utmContent,
          pricing: price,
          language: lang,
          chatEnabled: chat,
          latestSource: source,
          userAgent: window?.navigator?.userAgent,
          fbp: _fbp || "",
          fbc: _fbc || "",
        });
        const gSheetRes = await fetch(SCRIPT_URL, {
          method: "POST",
          body: JSON.stringify({
            ...details,
            phoneNumber: formattedPhoneNumber,
            leadId,
            utmSource,
            utmMedium,
            utmCampaign,
            utmContent,
            pricing: price,
            language: lang,
            chatEnabled: chat,
          }),
        });

        const { id: transactionId } = await createTransaction(
          leadId,
          price * 100,
          ACTIVE_PG,
          formattedPhoneNumber
        );
        setStorageItem(STORAGE_KEY.LEAD_ID, leadId);
        setStorageItem(STORAGE_KEY.TXN_ID, transactionId);
        setStorageItem(STORAGE_KEY.PHONE, formattedPhoneNumber);
        removeStorageItem(STORAGE_KEY.PAYMENT_SUCCESS);
        removeStorageItem(STORAGE_KEY.PAYMENT_FAILED);
        mixpanel.track(mixpanelEvents.FORM_SUBMITTED_SUCCESSFULLY);
        mixpanel.identify(formattedPhoneNumber);
        mixpanel.people.set_once({
          Phone: formattedPhoneNumber,
          Name: name,
          Email: email,
          DOB: dob,
          Pincode: pincode,
          Rejected: rejectedCredit,
          Cibil: cibilScore,
          Reason: reasonToImprove,
          Slot: timeSlot,
          MonthlyIncome: monthlyIncome,
          $CleverTap_user_id: leadId,
          utmSource,
          utmMedium,
          utmCampaign,
          utmContent,
        });

        clevertap.onUserLogin.push({
          Site: {
            Identity: leadId,
            Phone: "+91" + formattedPhoneNumber,
            "MSG-whatsapp": true,
          },
        });

        //gupshup opt in
        await optInGp(formattedPhoneNumber);
        if (window?.fbq) {
          window.fbq("track", "CompleteRegistration");
        }
        clevertap.event.push(clevertapEvents.USER_DETAILS_FORM_SUBMIT_SUCCESS);
        await onSubmitPhonePay(
          transactionId,
          price * 100,
          leadId,
          formattedPhoneNumber
        );
        // setTxnId(transactionId);
        // setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
        mixpanel.track(mixpanelEvents.FORM_SUBMISSION_ERROR, { error });
      }
    } else {
      formRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
      mixpanel.track(mixpanelEvents.FORM_VALIDATION_FAILED, { validation });
    }
  };

  const onSubmitPhonePay = async (
    transactionId,
    price,
    leadId,
    phoneNumber
  ) => {
    const redirectUrl =
      source && source === "facebook"
        ? "https://seva.rupicard.com/payment-status"
        : "https://seva.rupicard.com/payment-status-assisted";
    try {
      const phonepeResponse = await createPayment(
        `${transactionId}-${phoneNumber}`,
        price,
        leadId,
        phoneNumber,
        redirectUrl
      );

      await updateTransaction(transactionId, {
        status: phonepeResponse.code,
      });
      if (
        phonepeResponse.success &&
        phonepeResponse.code === "PAYMENT_INITIATED"
      ) {
        window.open(
          phonepeResponse?.data?.instrumentResponse?.redirectInfo?.url,
          "_self"
        );
        setSubmitting(false);
        return;
      }
    } catch (error) {}
  };

  const getHash = () => {
    return sha512(
      `${MERCHANT_KEY}|${txnId}|${amount}|${productInfo}|${name}|${email}|||||||||||${SALT}`
    );
  };

  const prefillLead = async (leadId) => {
    try {
      const lead = await getLead(leadId);
      if (lead.exists()) {
        const leadDetails = lead.data();
        const {
          name,
          phoneNumber,
          dob,
          pincode,
          rejectedCredit,
          cibilScore,
          reasonToImprove,
          timeSlot,
          email,
          monthlyIncome,
        } = leadDetails;

        setDetails({
          name,
          phoneNumber,
          dob,
          pincode,
          rejectedCredit,
          cibilScore,
          reasonToImprove,
          timeSlot,
          email,
          monthlyIncome,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   if (txnId) {
  //     payuRef.current?.submit();
  //   }
  // }, [txnId]);

  useEffect(() => {
    if (formSubmitted) {
      const validation = validator.validate(details);
      setValidation(validation);
    }
  }, [details]);

  useEffect(() => {
    if (window?.fbq) {
      window.fbq("track", "ViewContent");
    }
    mixpanel.track(mixpanelEvents.DETAILS_PAGE_VIEWED);
    const leadId = getStorageItem(STORAGE_KEY.LEAD_ID);
    if (leadId) {
      prefillLead(leadId);
    }
  }, []);

  const PayUForm = () => (
    <form action={prodUrl} method="post" id="pay" ref={payuRef}>
      <input type="hidden" name="key" value={MERCHANT_KEY} />
      <input type="hidden" name="txnid" value={txnId} />
      <input type="hidden" name="productinfo" value={productInfo} />
      <input type="hidden" name="amount" value={amount} />
      <input type="hidden" name="firstname" value={name} />
      <input type="hidden" name="email" value={email} />
      <input type="hidden" name="surl" value={SUCCESS_URL} />
      <input type="hidden" name="furl" value={FAILED_URL} />
      <input type="hidden" name="phone" value={phoneNumber} />
      <input type="hidden" name="hash" value={getHash()} />
    </form>
  );

  return (
    <Layout>
      <h1 className={classes.header}>{t("form.header")}</h1>

      <form className={classes.form} ref={formRef}>
        <div className={classes.inputContainer}>
          <label>Name</label>
          <input
            id="name"
            name="name"
            value={name}
            onChange={(e) => onRadioChange("name", e?.target?.value)}
            placeholder="Your name goes here"
          />
          <span className={classes.helperText}>
            {validation?.name?.message}
          </span>
        </div>
        <div className={classes.inputContainer}>
          <label>WhatsApp Number</label>
          <input
            id="phoneNumber"
            name="phoneNumber"
            value={phoneNumber}
            onChange={(e) => onRadioChange("phoneNumber", e?.target?.value)}
            placeholder="Enter your WhatsApp number"
          />
          <span className={classes.helperText}>
            {validation?.phoneNumber?.message}
          </span>
        </div>
        <div className={classes.inputContainer}>
          <label>Date Of Birth (DD-MM-YYYY)</label>
          <input
            id="dob"
            name="dob"
            value={dob}
            onChange={(e) => onRadioChange("dob", e?.target?.value)}
            placeholder="e.g. 02-02-1995"
          />
          <span className={classes.helperText}>{validation?.dob?.message}</span>
        </div>

        <div className={classes.inputContainer}>
          <label>Email</label>
          <input
            id="email"
            name="email"
            value={email}
            onChange={(e) => onRadioChange("email", e?.target?.value)}
            placeholder="Your email id goes here"
          />
          <span className={classes.helperText}>
            {validation?.email?.message}
          </span>
        </div>

        <div className={classes.inputContainer}>
          <label>Pincode</label>
          <input
            id="pincode"
            name="pincode"
            value={pincode}
            onChange={(e) => onRadioChange("pincode", e?.target?.value)}
            placeholder="Enter your address pincode "
          />
          <span className={classes.helperText}>
            {validation?.pincode?.message}
          </span>
        </div>

        <div className={classes.inputContainer}>
          <label>Monthly Income</label>
          <input
            id="monthlyIncome"
            name="monthlyIncome"
            value={monthlyIncome}
            onChange={(e) => onRadioChange("monthlyIncome", e?.target?.value)}
            placeholder="Enter your monthly income"
          />
          <span className={classes.helperText}>
            {validation?.monthlyIncome?.message}
          </span>
        </div>

        <div
          className={classes.questionWrpr}
          onChange={(e) => onRadioChange("rejectedCredit", e?.target?.value)}
        >
          <p className={classes.question}>{t("form.rejectedLoan")}</p>
          <div className={classes.option}>
            <input
              type="radio"
              value="Yes"
              id="yes"
              name="rejectedCredit"
              className={classes.input}
              checked={rejectedCredit === "Yes"}
            />
            <label htmlFor="yes" className={classes.label}>
              Yes
            </label>
          </div>
          <div className={classes.option}>
            <input
              type="radio"
              value="No"
              id="no"
              name="rejectedCredit"
              className={classes.input}
              checked={rejectedCredit === "No"}
            />
            <label htmlFor="no" className={classes.label}>
              No
            </label>
          </div>
          <span className={classes.helperText}>
            {validation?.rejectedCredit?.message}
          </span>
        </div>

        <div className={classes.inputContainer}>
          <label>{t("form.cibilScore")}</label>
          <input
            id="cibilScore"
            name="cibilScore"
            value={cibilScore}
            onChange={(e) => onRadioChange("cibilScore", e?.target?.value)}
            placeholder="Enter your cibil score"
          />
          <span className={classes.helperText}>
            {validation?.cibilScore?.message}
          </span>
        </div>

        <div className={classes.questionWrpr}>
          <p className={classes.question}>{t("form.timeSlot")}</p>
          <div className={classes.slots}>
            <div
              className={classNames(classes.box, {
                [classes.activeBox]: timeSlot === "10 AM - 1 PM",
              })}
              onClick={() => setTimeSlot("10 AM - 1 PM")}
            >
              10 AM - 1 PM
            </div>
            <div
              className={classNames(classes.box, {
                [classes.activeBox]: timeSlot === "1 PM - 4 PM",
              })}
              onClick={() => setTimeSlot("1 PM - 4 PM")}
            >
              1 PM - 4 PM
            </div>
            <div
              className={classNames(classes.box, {
                [classes.activeBox]: timeSlot === "4 PM - 7 PM",
              })}
              onClick={() => setTimeSlot("4 PM - 7 PM")}
            >
              4 PM - 7 PM
            </div>
          </div>
        </div>
        <div className={classes.inputContainer}>
          <label>{t("form.reason")}</label>
          <input
            id="reasonToImprove"
            name="reasonToImprove"
            value={reasonToImprove}
            onChange={(e) => onRadioChange("reasonToImprove", e?.target?.value)}
            placeholder="Enter your reason"
          />
        </div>
        <button
          className={classes.btn}
          onClick={onSubmit}
          disabled={submitting}
        >
          {submitting ? "Processing your request" : `Pay ${formattedPrice}`}
        </button>
      </form>
      <PayUForm />
    </Layout>
  );
};
