import React, { useContext } from "react";
import RupiSevaLogo from "../../assets/logo/goodscore.svg";
import WhatsAppIcon from "../../assets/icon/whatsapp.svg";
import { useStyles } from "./topnav.styles";
import { useNavigate } from "react-router-dom";
import { PAGES } from "../../utils/constant";
import mixpanel from "mixpanel-browser";
import { mixpanelEvents } from "../../services/mixpanel/events";
import { context } from "../../store/context";
export const TopNav = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const onLogoClick = () => navigate(PAGES.home);
  const { chat } = useContext(context);

  const onChatNow = () => {
    mixpanel.track(mixpanelEvents.CHAT_NOW_CLICKED);
    window.location.replace("https://wa.me/917439319184");
  };

  return (
    <div className={classes.root}>
      <img
        src={RupiSevaLogo}
        alt="Goodscore"
        onClick={onLogoClick}
        className={classes.logo}
      />
      {chat && (
        <button className={classes.chatBtn} onClick={onChatNow}>
          <img src={WhatsAppIcon} alt="whatsapp" />
          <p className={classes.btnText}>Chat Now!</p>
        </button>
      )}
    </div>
  );
};
